<template>
  <v-container>
    <v-card>
      <v-stepper v-model="step" class="elevation-0">
        <v-stepper-header class="subtitle-1 font-weight-regular">
          <v-stepper-step step="1" :complete="step > 1"
            >Privacidade</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="step > 2">Acesso</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="step > 3"
            >Telefone</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step step="4" :complete="step > 4"
            >Dados do Usuário</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step step="5" :complete="step > 5"
            >Foto de Perfil</v-stepper-step
          >
        </v-stepper-header>
        <v-stepper-content step="1">
          <step-privacidade
            @confirmar="politicas_aceitas = true"
          ></step-privacidade>
        </v-stepper-content>
        <v-stepper-content step="2">
          <step-acesso
            ref="stepAcesso"
            @confirmar="acesso_aceito = true"
            @rejeitar="acesso_aceito = false"
          ></step-acesso>
        </v-stepper-content>
        <v-stepper-content step="3">
          <step-telefone
            ref="stepTelefone"
            @confirmado="confirmado => (telefone_confirmado = confirmado)"
          ></step-telefone>
        </v-stepper-content>
        <v-stepper-content step="4">
          <step-dados
            ref="stepDados"
            @confirmar="dados_usuario_aceito = true"
            @rejeitar="dados_usuario_aceito = false"
          ></step-dados>
        </v-stepper-content>
        <v-stepper-content step="5">
          <step-foto-perfil @confirmar="inserirFoto"></step-foto-perfil>
        </v-stepper-content>
        <v-stepper-content step="6">
          <step-sucesso-insercao></step-sucesso-insercao>
        </v-stepper-content>
      </v-stepper>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="step < 6"
          text
          color="green"
          :disabled="continuarDisabled"
          :loading="loading && !continuarDisabled"
          @click="passarTela()"
        >
          <template v-if="step < 5">
            CONTINUAR
          </template>
          <template v-else>
            FINALIZAR CADASTRO
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
const StepPrivacidade = () => import("../components/steps/Privacidade");
const StepAcesso = () => import("../components/steps/DadosAcesso");
const StepDados = () => import("../components/steps/DadosUsuario");
const StepFotoPerfil = () => import("../components/steps/FotoPerfil");
const StepSucessoInsercao = () => import("../components/steps/SucessoInsercao");
const StepTelefone = () => import("../components/steps/Telefone");
export default {
  components: {
    StepPrivacidade,
    StepAcesso,
    StepDados,
    StepFotoPerfil,
    StepSucessoInsercao,
    StepTelefone
  },
  data() {
    return {
      step: 1,
      politicas_aceitas: false,
      acesso_aceito: false,
      dados_usuario_aceito: false,
      temp_imagem: null,
      telefone_confirmado: false,
      telefone: null
    };
  },
  mounted() {
    this.$root.$on("reset_fields", () => {
      this.step = 1;
      this.politicas_aceitas = false;
      this.acesso_aceito = false;
      this.dados_usuario_aceito = false;
      this.temp_imagem = null;
    });
  },
  methods: {
    passarTela() {
      switch (this.step) {
        case 1:
          if (this.politicas_aceitas) {
            this.step++;
          }
          break;
        case 2:
          this.verificarUsuario();
          break;
        case 3:
          this.vincularTelefone();
          break;
        case 4:
          this.inserirPessoa();
          break;
        default:
          if (!this.continuarDisabled) {
            if (this.temp_imagem) {
              this.confirmarFoto();
            } else {
              this.step++;
              this.$store.dispatch("sendMailVerification");
            }
          }
          break;
      }
    },
    vincularTelefone() {
      this.$store
        .dispatch("confirmSmsVerification", this.$refs.stepTelefone.codigo)
        .then(() => {
          this.step++;
          this.telefone = this.$refs.stepTelefone.telefoneUnmasked;
          this.$refs.stepTelefone.step = 1;
          this.$refs.stepTelefone.telefone = null;
          this.$refs.stepTelefone.codigo = null;
        })
        .catch(() => {
          this.$refs.stepTelefone.step = 1;
          this.$refs.stepTelefone.codigo = null;
        });
    },
    verificarUsuario() {
      this.$store
        .dispatch("signUserUp", this.$refs.stepAcesso.userData)
        .then(() => {
          this.step++;
          return;
        })
        .catch(() => {
          this.$store
            .dispatch("signUserIn", this.$refs.stepAcesso.userData)
            .then(() => {
              this.$store.dispatch("carregarDadosPerfil").then(value => {
                if (value) {
                  this.$store.dispatch("signUserOut");
                } else {
                  if (this.user.phoneNumber) {
                    this.telefone = this.user.phoneNumber.slice(3);
                    this.step += 2;
                  } else this.step++;
                }
              });
            });
        });
    },
    inserirPessoa() {
      let tempDados = this.$refs.stepDados.unmaskedData;
      this.$store
        .dispatch("inserirPessoa", {
          ...tempDados,
          telefone: `+55${this.telefone}`
        })
        .then(() => {
          this.step++;
        });
    },
    inserirFoto(imagem) {
      this.temp_imagem = imagem;
    },
    confirmarFoto() {
      this.$store
        .dispatch("atualizarFotoPerfil", this.temp_imagem)
        .then(() => {
          this.step++;
          this.$store.dispatch("sendMailVerification");
        })
        .catch(() => {
          this.step++;
          this.$store.dispatch("sendMailVerification");
        });
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    user() {
      return this.$store.getters.user;
    },
    continuarDisabled() {
      switch (this.step) {
        case 1:
          return !this.politicas_aceitas;
        case 2:
          return !this.acesso_aceito;
        case 3:
          return !this.telefone_confirmado;
        case 4:
          return !this.dados_usuario_aceito;
        default:
          return false;
      }
    }
  }
};
</script>
